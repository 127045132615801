<style lang="scss" scoped>

.city-select {
    width: 100%;
}

.select-city-pub.city-pub_border /deep/ {
    .el-select .el-input.is-focus .el-input__inner {
        border: 0;
    }

    .no_suffix {
        .el-input__inner {
            border: 0;
            padding-right: 15px;
        }

        .el-input__suffix {
            display: none;
            opacity: 0;
        }
    }
}

</style>
<template>
    <div class="select-city-pub" :class="{'city-pub_border':isBorder}">
        <el-select :placeholder="placeholder"
                   ref="select"
                   class="city-select"
                   :class="noSuffix?'no_suffix':''"
                   filterable
                   clearable
                   :size="size"
                   v-model="cityName"
                   @change="selectCity"
                   @focus="onFocus"
                   :loading="loading"
                   @visible-change="viChangeCity">
            <el-option
                v-for="(item) in serverCityList"
                :key="item.id"
                :label="item.name"
                :value="item.name">
            </el-option>
        </el-select>
    </div>

</template>

<script type="text/ecmascript-6">
import {getCityData} from '@/urls/index';

export default {
    //定义模版数据
    data() {
        return {
            cityName: this.defaultCity,
            cityList: [],
            isFocus: '',
            loading: true
        }
    },
    props: {
        defaultCity: {
            type: String,
        },
        defaultIndex: {
            type: Number,
            default: null
        },
        defaultCode: {
            type: String,
            default: null
        },
        defaultIsFocus: {
            type: Boolean,
            default: false
        },
        isBorder: {
            type: Boolean,
            default: true
        },
        noSuffix: {
            type: Boolean,
            default: true
        },
        size: {
            type: String,
            default: 'small'
        },
        placeholder: {
            type: String,
            default: "请选择城市"
        },
        ids: {
            type: Array,
            default: () => null
        },
        immediate: {
            type: Boolean,
            default: false
        }
    },
    //计算属性
    computed: {
        serverCityList() {
            return this.ids ? this.cityList.filter(({shortCode}) => this.ids.indexOf(shortCode) !== -1) : this.cityList
        }
    },
    //主件被加载完成
    mounted: function () {
        this.isFocus = this.defaultIsFocus;
        if (this.immediate)
            this.getServerCity();
    },
    //定义事件方法
    methods: {
        async getServerCity() {
            try {
                this.loading = true;
                let res = await getCityData(this, '2');
                if (res) {
                    this.cityList = res;
                } else
                    this.cityList = [];
                this.setDefault();
                this.loading = false;

            } catch (e) {
                console.log(e);
            }

        },
        selectCity(val) {
            this.$emit('update:getCity', val);
            this.$emit('update:city', this.sendCity(val));
        },
        sendCity(val) {
            return this.serverCityList.find(({city}) => city === val) || {}
        },
        getCity() {
            return this.cityName;
        },
        setDefault() {
            if (this.serverCityList.length) {
                if (this.defaultIndex)
                    this.cityName = this.serverCityList[this.defaultIndex].name;
                if (this.defaultCode)
                    this.cityName = (this.serverCityList.find(({shortCode}) => shortCode === this.defaultCode) || {}).name;
                this.selectCity(this.cityName)
                console.log(this.cityName, this.cityName);
            }
        },
        viChangeCity(b) {
            this.isFocus = b;
            this.$emit("onUpdate:isFocus", this.isFocus);
        },
        onFocus() {
            if (this.serverCityList.length === 0)
                this.getServerCity();
        },
        clear() {
            this.cityName = ''
        },
        blur() {
            this.$refs.select.blur();
        }
    },
    //监听模版变量
    watch: {
        defaultCity(n) {
            this.cityName = n;
        },
        defaultIndex() {
            this.setDefault();
        },
        defaultCode() {
            this.setDefault();
        },
        isFocus(n) {
            this.isFocus = n;
        }
    }

}
</script>
